import React, { useContext, useCallback, useState, useEffect } from "react";
import styled from "styled-components";

// Context
import { StoreContext } from "../context/store-context";

// Utils
import debounce from "lodash.debounce";

const Product = styled.div`
  font-family: "mabry-light";
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;

  max-width: 75%;
  margin: 0 0 20px 0;

  & .remove-button {
    margin: 20px 0 0 0;
  }
`;

const Title = styled.p`
  text-transform: uppercase;
  margin: 1em 0 0 0;

  & span:before {
    content: "·";
    padding: 0 10px;
    color: #000;
  }
`;

const Quantity = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;

  letter-spacing: 1.5px;

  & button {
    font-size: 14px;
    padding: 0 10px;
  }
`;

export const LineItem = ({
  item,
  currency,
  exchangeRate,
  lineItemMaxAvailableQuantity,
}) => {
  const { removeLineItem, checkout, updateLineItem, loading } =
    useContext(StoreContext);
  const [quantity, setQuantity] = useState(item.quantity);

  // Price
  const price = item.variant.priceV2.amount;
  const [formattedPrice, setFormattedPrice] = useState(price);

  useEffect(() => {
    const convertedResult = price * exchangeRate;
    const formattedResult = Intl.NumberFormat(undefined, {
      currency: currency,
      minimumFractionDigits: 2,
      style: "currency",
    }).format(convertedResult);
    setFormattedPrice(formattedResult);
  }, [currency, exchangeRate]);

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  );
  // eslint-disable-next-line
  const debouncedUli = useCallback((value) => uli(value), []);

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1);
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1);
  }

  return (
    <Product>
      <img src={item.variant.image.src} alt={item.title} loading="lazy" />

      <Title>
        {item.title}

        {item.variant.title !== "Default Title" && (
          <>
            <br />
            {item.variant.title}
          </>
        )}
        <span>{formattedPrice}</span>
      </Title>
      <Quantity>
        quantity: {item.quantity}
        <div>
          <button onClick={doDecrement}>-</button>/
          <button
            onClick={doIncrement}
            disabled={item.quantity === lineItemMaxAvailableQuantity}
          >
            +
          </button>
        </div>
      </Quantity>

      <button onClick={handleRemove} className="remove-button">
        Remove
      </button>
    </Product>
  );
};

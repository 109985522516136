import React, { createContext } from "react";

const Currency = createContext({
  currency: "GBP",
  setCurrency: () => {},
  exchangeRate: 1,
});

async function convertCurrency(incomingCurrency) {
  const host = "api.frankfurter.app";

  if (incomingCurrency === `GBP`) {
    return 1;
  } else {
    return await fetch(`https://${host}/latest?from=GBP&to=${incomingCurrency}`)
      .then(resp => resp.json())
      .then(data => data.rates[incomingCurrency])
      .catch(error => console.log(error));
  }
}

export class CurrencyProvider extends React.Component {
  setCurrency = async newCurrency => {
    this.setState({ currency: newCurrency });
    this.setState({ exchangeRate: await convertCurrency(newCurrency) });
  };

  state = {
    currency: "GBP",
    setCurrency: this.setCurrency,
    exchangeRate: 1,
  };

  render() {
    return (
      <Currency.Provider value={this.state}>
        {this.props.children}
      </Currency.Provider>
    );
  }
}

export const CurrencyConsumer = Currency.Consumer;

import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import MenuIcon from '../icons/menu-icon';

const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 76px 30px;

  min-height: 100%;

  background-color: #efede5;
`;

const Navigation = styled.nav`
  display: block;
  position: relative;
  z-index: 1;

  min-height: 100%;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    width: 100%;

    & a {
      font-size: 20px;
      line-height: 45px;
    }
  }

  li.sub-navigation {
    & a {
      font-size: 11px;
      line-height: 26px;
      font-family: 'mabry-light';
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  a {
    text-decoration: none;
    text-transform: capitalize;

    color: #000;

    &:active,
    &:hover {
      color: #000;
    }
  }
`;

const MenuToggleIcon = styled.button`
  width: 18px;
  position: absolute;
  top: 28px;
  left: 25px;
  transform: rotate(45deg);

  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 500ms opacity ease;
  transition-delay: 400ms;
`;

const MobileMenu = ({ menuLinks, onClickMobileMenu, isOpen }) => (
  <MobileMenuContainer>
    <MenuToggleIcon onClick={() => onClickMobileMenu()} visible={isOpen}>
      <MenuIcon />
    </MenuToggleIcon>
    <Navigation>
      <ul>
        {menuLinks.map(link => (
          <li key={link.name}>
            <Link to={link.link} onClick={() => onClickMobileMenu()}>
              {link.name}
            </Link>
          </li>
        ))}
        <br />
        <br />
        <li className="sub-navigation">
          <Link to={`/contact`} onClick={() => onClickMobileMenu()}>
            Contact
          </Link>
        </li>
        <li className="sub-navigation">
          <Link to={`/press`} onClick={() => onClickMobileMenu()}>
            Press
          </Link>
        </li>
        <li className="sub-navigation">
          <Link
            to={`/policies/privacy-policy`}
            onClick={() => onClickMobileMenu()}
          >
            Policies
          </Link>
        </li>
        <li className="sub-navigation">
          <Link to={`/policies/faq`} onClick={() => onClickMobileMenu()}>
            FAQ
          </Link>
        </li>
        <li className="sub-navigation">
          <a
            href="https://www.instagram.com/maisonartefact"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
        </li>
      </ul>
    </Navigation>
  </MobileMenuContainer>
);

export default MobileMenu;

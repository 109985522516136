import React, { useContext, useState, useEffect } from "react";

import styled from "styled-components";
import CartIcon from "../icons/cart-icon";

// Context
import { StoreContext } from "../context/store-context";

const CartTotalContainer = styled.span`
  & .quantity {
    position: absolute;
    top: 13px;
    left: 10px;

    font-size: 11px;
  }
`;

const countQuantity = (lineItems) => {
  let quantity = 0;

  lineItems.forEach((item) => {
    quantity = quantity + item.quantity;
  });

  return quantity;
};

export const CartTotal = () => {
  const { checkout } = useContext(StoreContext);

  const [quantity, setQuantity] = useState(
    countQuantity(checkout ? checkout.lineItems : [])
  );

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []));
  }, [checkout]);

  return (
    <CartTotalContainer>
      {quantity !== 0 && <span className="quantity">{quantity}</span>}
      <CartIcon />
    </CartTotalContainer>
  );
};

import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Row, Col } from "styled-bootstrap-grid";
import DesktopMenu from "./desktop-menu";
import Logo from "../icons/logo";
import { CartTotal } from "./cart-total";

const MenuCartContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CartToggle = styled.button`
  width: 25px;
  height: 27px;

  position: relative;
`;

const HeaderContainer = styled.header`
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  background-color: #fafafa;

  padding: 0 60px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  transform: translateY(${(props) => (props.showMenu ? "0" : "-100%")});
  transition: 250ms transform ease;

  z-index: 1000;

  line-height: 65px;
  height: 65px;

  & a {
    line-height: 1;
  }
`;

const HeaderColumn = styled.div`
  align-items: center;
  width: 100%;

  & .home {
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;

const DesktopHeader = ({ location, menuLinks, onClick, showMenu }) => (
  <HeaderContainer location={location} showMenu={showMenu}>
    <HeaderColumn>
      <Row alignItems="center">
        <Col col={12} md={5}>
          <div className="home">
            <Link to={"/"}>
              <Logo />
            </Link>
          </div>
        </Col>

        <Col col={12} md={7}>
          <MenuCartContainer>
            <DesktopMenu menuLinks={menuLinks} />
            <CartToggle onClick={() => onClick()}>
              <CartTotal />
            </CartToggle>
          </MenuCartContainer>
        </Col>
      </Row>
    </HeaderColumn>
  </HeaderContainer>
);

export default DesktopHeader;

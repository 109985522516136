import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  border: 1px solid rgb(51, 51, 51);
  height: 30px;

  margin: 0 0 15px 0;
  box-sizing: content-box;

  line-height: 31px;

  & .message {
    width: calc(100% - 105px - 11px);

    font-family: 'spectral-extra-light';
    font-size: 14px;

    padding: 0 0 0 10px;

    background-color: transparent;
    -webkit-appearance: none;

    border: 0;

    &:focus {
      outline: none;
    }

    position: relative;
    line-height: 31px;

    & p {
      margin: 0;
      line-height: 31px;
    }
  }
`;

const MailchimpFormContainer = styled.div`
  max-width: 300px;

  @media (max-width: 500px) {
    max-width: 100%;
    width: 100%;
  }
`;

const SignUpButton = styled.button`
  font-family: 'spectral-extra-light-italic';
  line-height: 31px;
  font-size: 14px;
  text-transform: capitalize;

  -webkit-appearance: none;
  letter-spacing: 0.3px;

  width: 105px;
  border-left: 1px solid rgb(51, 51, 51);

  cursor: pointer;

  &:focus {
    outline: none;
  }
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: rgb(51, 51, 51);

    & a {
      color: #f5f5f5;
    }
  }
`;

export const SignUpForm = () => (
  <MailchimpFormContainer>
    <InputContainer>
      <div className="message">
        <p>Join our Mailing List</p>
      </div>

      <SignUpButton>
        <Link to={`/contact`}>Subscribe Here</Link>
      </SignUpButton>
    </InputContainer>
  </MailchimpFormContainer>
);

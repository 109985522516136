import React from "react";
import styled from "styled-components";

// Components
import { DefaultSEO } from "./default-seo";
import Header from "./header";
import Footer from "./footer";

// Context
import {
  CurrencyProvider,
  CurrencyConsumer,
} from "../currency/currency-context";

const ContentContainer = styled.div`
  flex: 1;
  margin-right: auto;
  margin-left: auto;
  margin-top: ${props => (props.location === "/" ? "0" : "135px")};
  width: 100%;

  @media (max-width: 768px) {
    margin-top: ${props => (props.location === "/" ? "0" : "100px")};
  }
`;

const App = ({ data, children, location }) => (
  <>
    <DefaultSEO location={location} />

    <CurrencyProvider>
      <CurrencyConsumer>
        {({ currency }) => (
          <>
            <Header menuLinks={data.site.siteMetadata.menuLinks} />

            <ContentContainer location={location.pathname}>
              <>{children}</>
            </ContentContainer>
            <Footer />
          </>
        )}
      </CurrencyConsumer>
    </CurrencyProvider>
  </>
);

export default App;

import React, { useState } from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';

// Context
import { CurrencyConsumer } from '../currency/currency-context';

const Arrow = styled.span`
  font-family: 'mabry-light';
  text-transform: uppercase;
  font-size: 11px;

  cursor: pointer;
  transform: rotate(${props => (props.isOpen ? '-180deg' : '0')});
  color: #000;
`;

const CurrencyContainer = styled.div`
  position: absolute;
  width: 50px;

  font-family: 'mabry-light';
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;

  & .cc-dropdown__control {
    border: 0;
    background-color: inherit;

    border-radius: 0;

    min-height: 17px;

    cursor: pointer;
  }

  & .cc-dropdown__control--is-focused {
    box-shadow: none;
  }

  & .cc-dropdown__value-container {
    padding: 0;
  }

  & .cc-dropdown__single-value,
  & .cc-dropdown__placeholder {
    margin: 0;
    padding: 0 0 2px 0;
    color: #000;

    position: relative;
    top: 0;
    transform: none;
  }

  & .cc-dropdown__placeholder {
  }

  & .cc-dropdown__menu-list {
    padding: 0;
  }

  & .cc-dropdown__indicator-separator {
    display: none;
  }

  & .cc-dropdown__indicator {
    padding: 0;

    & > svg {
      width: 10px;
      height: 10px;
    }
  }

  & .cc-dropdown__menu {
    border-radius: 0;
    box-shadow: none;
    background-color: #fafafa;
  }

  & .cc-dropdown__option--is-focused {
    background-color: #f5f5f5;
  }

  & .cc-dropdown__option--is-selected {
    background-color: #f5f5f5;
    color: #000;
  }

  & .cc-css-b8ldur-Input {
    margin: 0;
    padding: 0;
  }
`;

const options = [
  { value: 'GBP', label: 'GBP' },
  { value: 'EUR', label: 'EUR' },
  { value: 'USD', label: 'USD' },
];

const CurrencyConverterContent = ({ setCurrency, currency }) => {
  const [currentCurrency, setCurrentCurrency] = useState(currency);

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Arrow isOpen={props.selectProps.menuIsOpen}>↓</Arrow>
        </components.DropdownIndicator>
      )
    );
  };

  const updateActiveCurrency = currency => {
    setCurrency(currency.value);
  };

  return (
    <CurrencyContainer>
      <Select
        options={options}
        placeholder={currentCurrency}
        isSearchable={false}
        classNamePrefix="cc-dropdown"
        onChange={currency => updateActiveCurrency(currency)}
        components={{ DropdownIndicator }}
      />
    </CurrencyContainer>
  );
};

export const CurrencyConverter = () => (
  <CurrencyConsumer>
    {({ currency, setCurrency }) => (
      <CurrencyConverterContent setCurrency={setCurrency} currency={currency} />
    )}
  </CurrencyConsumer>
);

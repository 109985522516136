import React, { useState } from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import MenuIcon from '../icons/menu-icon';

const Navigation = styled.nav`
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 1;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    padding-left: 30px;
  }
  a {
    text-decoration: none;
    text-transform: capitalize;

    color: #000;

    font-size: 13px;

    position: relative;

    &:active,
    &:hover {
      color: #000;
    }
  }

  a.current:before {
    content: '';
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #6a6969;
    visibility: visible;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }

  a:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #6a6969;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  a:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

const MenuToggle = styled.button`
  font-family: 'mabry-light';
  text-transform: uppercase;
  font-size: 11px;

  letter-spacing: 1px;

  padding: 27px 0 26px 0;
`;

const MenuToggleIcon = styled.button`
  width: 14px;
  padding: 21px 0;

  margin-left: 15px;

  transform: ${props => (props.isMenuOpen ? 'rotate(45deg)' : 'rotate(0)')};
  transition: 500ms transform ease-in-out;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DesktopMenu = ({ menuLinks }) => {
  const [isOpen, setIsOpen] = useState(false);

  const container = {
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.03,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, display: 'none' },
    show: { opacity: 1, display: 'inline-block' },
  };

  return (
    <Menu>
      <MenuToggle onClick={() => setIsOpen(!isOpen)}>MENU</MenuToggle>

      <Navigation isMenuOpen={isOpen}>
        <motion.div
          variants={container}
          initial="hidden"
          animate={isOpen ? 'show' : 'hidden'}
        >
          <ul>
            {menuLinks.map(link => (
              <motion.li key={link.name} variants={item}>
                <Link
                  to={link.link}
                  onClick={() => setIsOpen(false)}
                  activeClassName={'current'}
                >
                  {link.name}
                </Link>
              </motion.li>
            ))}
          </ul>
        </motion.div>

        <MenuToggleIcon onClick={() => setIsOpen(!isOpen)} isMenuOpen={isOpen}>
          <MenuIcon />
        </MenuToggleIcon>
      </Navigation>
    </Menu>
  );
};

export default DesktopMenu;

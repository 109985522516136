import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export const DefaultSEO = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          desktop_image {
            url
          }
          text {
            text
          }
        }
      }
    }
  `);

  const pageTitle = location.pathname.split("/")[1];
  const capitalizedPageTitle =
    pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1);
  const finalPageTitle =
    capitalizedPageTitle === "" ? "Maison Artefact" : capitalizedPageTitle;

  return (
    <Helmet
      title={`${finalPageTitle} – Maison Artefact`}
      meta={[
        {
          name: "description",
          content: `${data.prismicHomepage.data.text.text}`,
        },
        {
          name: "og:image",
          content: `${data.prismicHomepage.data.desktop_image.url}`,
        },
        {
          name: "og:image:secure_url",
          content: `${data.prismicHomepage.data.desktop_image.url}`,
        },
        {
          name: "og:description",
          content: `${data.prismicHomepage.data.text.text}`,
        },
        {
          name: "og:image:width",
          content: `1200`,
        },
        {
          name: "og:image:height",
          content: `630`,
        },
        {
          name: "og:locale",
          content: `en`,
        },
        {
          name: "twitter:title",
          content: `Maison Artefact`,
        },
        {
          name: "twitter:description",
          content: `${data.prismicHomepage.data.text.text}`,
        },
        {
          name: "twitter:card",
          content: `summary_large_image`,
        },
        {
          name: "twitter:image",
          content: `${data.prismicHomepage.data.desktop_image.url}`,
        },
      ]}
      link={[
        {
          rel: "dns-prefetch",
          href: "https://images.prismic.io/maisonartefact",
        },
        {
          rel: "preconnect",
          href: "https://images.prismic.io/maisonartefact",
        },
        {
          rel: "dns-prefetch",
          href: "https://cdn.shopify.com/s/files",
        },
        {
          rel: "preconnect",
          href: "https://cdn.shopify.com/s/files",
        },
        {
          rel: "preload",
          href: "/fonts/fonts.css",
          as: "style",
        },
        {
          rel: "preload",
          href: "/fonts/mabry/mabry-light.eot",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/mabry/mabry-light.ttf",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/mabry/mabry-light.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/mabry/mabry-light.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/spectral/spectral-light.ttf",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/spectral/spectral-light.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/spectral/spectral-light.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
      ]}
    />
  );
};

import * as React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import fetch from "isomorphic-fetch";

import { StoreProvider } from "./src/components/context/store-context";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new createHttpLink({
    uri: `https://${process.env.SHOP_NAME}.myshopify.com/api/2022-07/graphql.json`,
    headers: {
      "X-Shopify-Storefront-Access-Token": process.env.SHOPIFY_ACCESS_TOKEN,
      Accept: "application/graphql",
    },
    fetch,
  }),
});

const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      about: React.lazy(() => import("./src/pages/about.js")),
      contact: React.lazy(() => import("./src/pages/contact.js")),
      homepage: React.lazy(() => import("./src/pages/index.js")),
      project_page: React.lazy(() => import("./src/pages/interiors.js")),
      order_confirmation: React.lazy(() =>
        import("./src/pages/order-confirmation.js")
      ),
      press: React.lazy(() => import("./src/pages/press.js")),
      project: React.lazy(() => import("./src/templates/interior.js")),
      text_page: React.lazy(() => import("./src/templates/policy.js")),
      product: React.lazy(() => import("./src/templates/product.js")),
      products: React.lazy(() => import("./src/templates/shop.js")),
    },
  },
];

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    <ApolloProvider client={apolloClient}>
      <StoreProvider>{element}</StoreProvider>
    </ApolloProvider>
  </PrismicPreviewProvider>
);

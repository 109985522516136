import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Components
import { CartTotal } from "./cart-total";
import Logo from "../icons/logo";
import MenuIcon from "../icons/menu-icon";

const MenuCartContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CartToggle = styled.button`
  width: 25px;
  height: 27px;

  position: relative;
`;

const HeaderContainer = styled.header`
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  background-color: #fafafa;

  padding: 25px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  transform: translateY(${(props) => (props.showMenu ? "0" : "-100%")});
  transition: 250ms transform ease;

  z-index: 100;

  line-height: 65px;

  & a {
    line-height: 1;
  }
`;

const HeaderColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  & .home {
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;

const MobileMenuToggle = styled.button`
  width: 18px;

  transform: ${(props) => (props.isMenuOpen ? "rotate(45deg)" : "rotate(0)")};
  transition: 500ms transform linear;
  z-index: 100;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const MobileHeader = ({
  location,
  isOpen,
  onClickMobileMenu,
  onClickToggleCart,
  showMenu,
}) => (
  <HeaderContainer location={location} showMenu={showMenu}>
    <HeaderColumn>
      <MenuContainer>
        <MobileMenuToggle onClick={() => onClickMobileMenu()}>
          <MenuIcon rotate={isOpen} />
        </MobileMenuToggle>

        <div className="home">
          <Link to={"/"}>
            <Logo />
          </Link>
        </div>

        <MenuCartContainer>
          <CartToggle onClick={() => onClickToggleCart()}>
            <CartTotal />
          </CartToggle>
        </MenuCartContainer>
      </MenuContainer>
    </HeaderColumn>
  </HeaderContainer>
);

export default MobileHeader;

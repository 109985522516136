import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg``;

const CartIcon = () => (
  <Icon viewBox="0 0 24 25">
    <defs>
      <clipPath id="cart-icon-clip-path">
        <rect x="0.41" width="23.18" height="25" fill="none" />
      </clipPath>
    </defs>
    <g>
      <g clipPath="url(#cart-icon-clip-path)">
        <polygon
          points="19.84 24.75 23.34 10.01 11.95 10.01 0.66 10.01 4.16 24.75 11.95 24.75 19.84 24.75"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="0.75"
        />
      </g>
      <line
        x1="12.05"
        y1="0.25"
        x2="12.05"
        y2="9.73"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
      />
    </g>
  </Icon>
);

export default CartIcon;

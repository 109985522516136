import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

// Components
import { CurrencyConverter } from "../currency/currency-converter";
import { LineItem } from "./line-item";
import MenuIcon from "../icons/menu-icon";

// Context
import { CurrencyConsumer } from "../currency/currency-context";
import { StoreContext } from "../context/store-context";

const CartContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-between;

  padding: 23px 25px 25px 25px;

  min-height: 100%;

  background-color: #e8ded5;

  & .empty-cart {
    font-family: "mabry-light";
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    text-align: left;

    width: 100%;

    margin: 50px 0 0 0;
  }

  & .shipping-text {
    font-family: "mabry-light";
    font-size: 11px;
    line-height: 17px;
    letter-spacing: 0.7px;

    align-self: flex-end;

    & a {
      border-bottom: 1px solid #000;
      transition: all 0.3s ease-in-out;

      &:hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }

  @media (max-width: 768px) {
    padding: 76px 30px;
  }

  @media (max-width: 500px) {
    padding: 30px;

    & .mobile-hide {
      display: none;
    }
  }
`;

const CheckoutButton = styled.div`
  & a {
    display: block;
    text-align: center;

    width: 100%;
    height: 35px;

    font-family: "spectral-extra-light";
    text-transform: capitalize;
    letter-spacing: 0.3px;

    margin: 20px 0 0 0;

    font-size: 16px;
    line-height: 35px;

    background-color: transparent;
    border: 1px solid #000;

    color: #000;

    transition: all 0.3s ease-in-out;

    &:hover {
      color: #fff;
      background-color: #000;
    }
  }
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & h2,
  & p {
    font-family: "mabry-light";
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;

    margin: 0;
  }

  & h2 {
    margin: 0 10px 0 0;
  }
`;

const MenuToggleIcon = styled.button`
  width: 18px;
  position: absolute;
  top: 28px;
  right: 25px;
  transform: rotate(45deg);
`;

const CartItems = styled.div`
  padding: 40px 0 0 0;
`;

const CheckoutContainer = styled.div`
  align-self: flex-end;
`;

const SlideOutCartContent = ({
  currency,
  exchangeRate,
  onClick,
  allProductAvailability,
}) => {
  const { checkout } = useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;

  const line_items = checkout.lineItems.map((line_item) => {
    const lineItemMaxAvailableQuantity = allProductAvailability.filter(
      (item) => {
        return item.node.shopifyId === line_item.variant.id;
      }
    );

    return (
      <LineItem
        key={line_item.id.toString()}
        item={line_item}
        currency={currency}
        exchangeRate={exchangeRate}
        lineItemMaxAvailableQuantity={
          lineItemMaxAvailableQuantity[0].node.inventoryQuantity
        }
      />
    );
  });

  // Price
  const price =
    checkout.totalPrice !== undefined ? checkout.totalPrice.amount : 1;
  const [formattedPrice, setFormattedPrice] = useState(0);

  useEffect(() => {
    if (checkout !== undefined) {
      const convertedResult = price * exchangeRate;
      const formattedResult = Intl.NumberFormat(undefined, {
        currency: currency,
        minimumFractionDigits: 2,
        style: "currency",
      }).format(convertedResult);
      setFormattedPrice(formattedResult);
    }
  }, [checkout, currency, exchangeRate]);

  return (
    <CartContainer>
      <MenuToggleIcon onClick={() => onClick()}>
        <MenuIcon />
      </MenuToggleIcon>
      <CurrencyConverter />
      {line_items.length >= 1 && <CartItems>{line_items}</CartItems>}

      {line_items.length >= 1 ? (
        <CheckoutContainer>
          <p className="shipping-text">
            National and worldwide shipping available.
            <br className="mobile-hide" /> Please{" "}
            <a href="mailto:mail@maisonartefact.com">contact us</a> for a
            quotation.
          </p>

          <TotalContainer>
            <h2>Subtotal</h2>
            <p>{formattedPrice}</p>
          </TotalContainer>

          <CheckoutButton>
            <a href={checkout.webUrl}>Checkout</a>
          </CheckoutButton>
        </CheckoutContainer>
      ) : (
        <>
          <p className="empty-cart">Your Cart Is Empty</p>

          <p className="shipping-text">
            National and worldwide shipping available.
            <br className="mobile-hide" /> Please{" "}
            <a href="mailto:mail@maisonartefact.com">contact us</a> for a
            quotation.
          </p>
        </>
      )}
    </CartContainer>
  );
};

const SlideOutCart = ({ onClick }) => {
  const data = useStaticQuery(graphql`
    {
      allShopifyProductVariant {
        edges {
          node {
            shopifyId
            inventoryQuantity
          }
        }
      }
    }
  `);

  return (
    <CurrencyConsumer>
      {({ currency, exchangeRate }) => (
        <SlideOutCartContent
          currency={currency}
          exchangeRate={exchangeRate}
          onClick={onClick}
          allProductAvailability={data.allShopifyProductVariant.edges}
        />
      )}
    </CurrencyConsumer>
  );
};

export default SlideOutCart;

import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  transform: rotate(${props => props.rotate});
  transition: 250ms transform ease;
`;

const MenuIcon = ({ rotate }) => (
  <Icon viewBox="0 0 14 14" rotate={rotate ? `45deg` : `0`}>
    <defs>
      <clipPath id="menu-toggle-clip-path">
        <rect width="14" height="14" fill="none" />
      </clipPath>
    </defs>
    <g clipPath="url(#menu-toggle-clip-path)">
      <line
        y1="7"
        x2="14"
        y2="7"
        fill="none"
        stroke="#000"
        strokeWidth="0.75"
      />
      <line
        x1="7"
        x2="7"
        y2="14"
        fill="none"
        stroke="#000"
        strokeWidth="0.75"
      />
    </g>
  </Icon>
);

export default MenuIcon;

import React from 'react';
import Menu from 'react-burger-menu/lib/menus/slide';
import Media from 'react-media';

import SlideOutCart from '../cart/slide-out-cart';
import '../styles/sidebar.css';

import MobileMenu from '../navigation/mobile-menu';
import DesktopHeader from '../navigation/desktop-header';
import MobileHeader from '../navigation/mobile-header';

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      cartOpen: false,
      mobileMenuOpen: false,
      windowWidth: 768,
      prevScrollpos: 0,
      showMenu: true,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  handleCartStateChange(state) {
    this.setState({
      cartOpen: state.isOpen,
    });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeCart() {
    this.setState({ cartOpen: false });
  }

  toggleCart() {
    this.setState(state => ({
      cartOpen: !state.cartOpen,
    }));
  }

  handleMobileMenuStateChange(state) {
    this.setState({
      mobileMenuOpen: state.isOpen,
    });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMobileMenu() {
    this.setState({ mobileMenuOpen: false });
  }

  toggleMobileMenu() {
    this.setState(state => ({
      mobileMenuOpen: !state.mobileMenuOpen,
    }));
  }

  componentDidMount() {
    // Get window dimensions on load
    if (typeof window !== `undefined`) {
      let windowWidth = window.innerWidth;
      this.setState({
        windowWidth: windowWidth,
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const showMenu =
      currentScrollPos <= 100 || prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      showMenu,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Media
          queries={{ medium: '(max-width: 768px)' }}
          defaultMatches={{ medium: this.state.windowWidth === 768 }}
          render={() => (
            <Menu
              isOpen={this.state.mobileMenuOpen}
              onStateChange={state => this.handleMobileMenuStateChange(state)}
              pageWrapId={'page-wrap'}
              outerContainerId={'___gatsby'}
              left
              width={400}
            >
              <MobileMenu
                menuLinks={this.props.menuLinks}
                onClickMobileMenu={() => this.toggleMobileMenu()}
                isOpen={this.state.mobileMenuOpen}
              />
            </Menu>
          )}
        />

        <Media
          queries={{ medium: '(max-width: 768px)' }}
          defaultMatches={{ medium: this.state.windowWidth === 768 }}
          render={() => (
            <MobileHeader
              menuLinks={this.props.menuLinks}
              location={this.props.location}
              onClickMobileMenu={() => this.toggleMobileMenu()}
              onClickToggleCart={() => this.toggleCart()}
              showMenu={this.state.showMenu}
              isOpen={this.state.mobileMenuOpen}
            />
          )}
        />

        <Media
          queries={{ medium: '(min-width: 769px)' }}
          defaultMatches={{ medium: this.state.windowWidth === 769 }}
          render={() => (
            <DesktopHeader
              menuLinks={this.props.menuLinks}
              location={this.props.location}
              onClick={() => this.toggleCart()}
              showMenu={this.state.showMenu}
            />
          )}
        />

        <Menu
          isOpen={this.state.cartOpen}
          onStateChange={state => this.handleCartStateChange(state)}
          pageWrapId={'page-wrap'}
          outerContainerId={'___gatsby'}
          right
          width={400}
        >
          <SlideOutCart onClick={() => this.toggleCart()} />
        </Menu>
      </React.Fragment>
    );
  }
}

export default Header;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Logo from '../icons/logo';
import { SignUpForm } from '../mailchimp/sign-up-form';

const FooterContainer = styled.div`
  width: 100%;
  padding: 55px 60px;

  background-color: #f5f5f5;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 780px) {
    padding: 45px 30px;
  }
`;

const FooterNavigation = styled.nav`
  position: relative;
  z-index: 1;

  line-height: 1;
  width: 100%;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    padding-left: 20px;

    display: inline-block;

    &:first-of-type {
      padding-left: 0;
    }

    @media (max-width: 500px) {
      padding: 0;
      width: 100%;
      line-height: 25px;
    }
  }

  a {
    text-decoration: none;

    font-family: 'mabry-light';
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;

    color: #000;
    transition: color 0.3s ease-in-out;

    &:active,
    &:hover {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  @media (max-width: 500px) {
    padding-top: 10px;
  }
`;

const Copyright = styled.div`
  font-size: 12px;
  line-height: 1;

  text-align: right;

  @media (max-width: 780px) {
    text-align: left;
  }
`;

const InnerContainerLeft = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  flex: 0 0 340px;

  @media (max-width: 780px) {
    align-items: flex-start;
    width: 100%;
    flex: 0 0 100%;

    margin-bottom: 30px;
  }
`;

const InnerContainerRight = styled.div`
  max-width: 300px;
  width: 100%;

  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const Footer = () => {
  var date = new Date();
  var year = date.getFullYear();

  return (
    <FooterContainer>
      <InnerContainerLeft>
        <div className="home">
          <Link to={'/'}>
            <Logo />
          </Link>
        </div>
        <FooterNavigation>
          <ul>
            <li>
              <Link to={`/contact`}>Contact</Link>
            </li>
            <li>
              <Link to={`/press`}>Press</Link>
            </li>
            <li>
              <Link to={`/policies/privacy-policy`}>Policies</Link>
            </li>
            <li>
              <Link to={`/policies/faq`}>FAQ</Link>
            </li>
            <li>
              <a
                href="https://www.instagram.com/maisonartefact"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
          </ul>
        </FooterNavigation>
      </InnerContainerLeft>
      <InnerContainerRight>
        <SignUpForm />
        <Copyright>
          © {year} Maison Artefact /{' '}
          <a
            href="https://www.kieranstartup.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Site Credits
          </a>
        </Copyright>
      </InnerContainerRight>
    </FooterContainer>
  );
};

export default Footer;

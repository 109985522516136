import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

*:focus{
  outline: none;
}

html,
html.wf-loading {
  body {
    opacity: 0;
    transition: 250ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

  body {
    color: #000;
    background-color: #fafafa;

    font-family: 'spectral-extra-light';
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.3px;

    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
  }

    em {
      font-family: 'spectral-extra-light-italic';
    }

    b,
    strong {
      font-weight: normal;
    }

    & a,
    & a:visited {
      text-decoration: none;
      color: inherit;
    }

    & a:focus{
      outline: none;
    }

    & a {
      transition: color 0.3s ease-in-out;
    }

    & a:hover{
        color: rgba(0,0,0,0.3);
    }


    h1, h2, h3, h4, h5, h6{
      margin: 0;
      font-weight: normal;
    }

    h1 {
      font-size: 15px;

      // @media (max-width: 767px) {
      //   font-size: 30px;
      //   line-height: 40px;
      // }
    }

    h2{
      // font-size: 49px;
      // line-height: 63px;

      // @media (max-width: 767px) {
      //     font-size: 26px;
      //     line-height: 32px;
      // }
    }

    h3{
      // font-size: 30px;
      // line-height: 40px;
      // letter-spacing: 0;

      // @media (max-width: 767px) {
      //     font-size: 22px;
      //     line-height: 28px;
      // }
    }


    button {
      font-family: 'mabry-light';
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 1px;

      cursor: pointer;
      padding: 0;
      margin: 0;
      -webkit-appearance: none;
      border: 0;
      background-color: transparent;

      color: #000;

      &:hover {
      color: #000;
      }

      &:focus {
        outline: 0;
      }

    }


    img {
      width: 100%;
      display: block;
    }


`;

export default GlobalStyle;

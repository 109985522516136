import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Location } from "@reach/router";
import styled from "styled-components";
import { BaseCSS, GridThemeProvider } from "styled-bootstrap-grid";
import { Normalize } from "styled-normalize";

import GlobalStyle from "../styles/globalStyles";
import App from "./app";

import "array-flat-polyfill";

const Container = styled.div`
  width: 100%;

  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 769,
    sm: 576,
    xs: 575,
  },
};

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  return (
    <GridThemeProvider gridTheme={gridTheme}>
      <Location>
        {({ location }) => {
          return (
            <Container location={location.pathname}>
              <Normalize />
              <BaseCSS />
              <GlobalStyle />
              <App location={location} data={data} children={children} />
            </Container>
          );
        }}
      </Location>
    </GridThemeProvider>
  );
};

export default Layout;

exports.linkResolver = doc => {
  switch (doc.type) {
    case "about": {
      return `/about/`;
    }

    case "homepage": {
      return `/`;
    }

    case "contact": {
      return `/${doc.uid}/`;
    }

    case "press": {
      return `/press/`;
    }

    case "products": {
      return `/products/`;
    }

    case "project_page": {
      return `/projects/`;
    }

    case "project": {
      return `/project/${doc.uid}/`;
    }

    case "text_page": {
      return `/${doc.uid}/`;
    }
  }
};
